import "./Assets.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import items from "../../contents/assets";
import AssetsSchema from "../../contents/assets/typedef";

export default function Assets() {
  function renderItem(eachItem: AssetsSchema, index: number): JSX.Element {
    return (
      <Card
        key={index}
        component="article"
        itemScope
        itemType="https://schema.org/Thing"
      >
        <img
          src={eachItem.image}
          alt={eachItem.title}
          draggable={false}
          itemProp="image"
        />
        <CardContent className="content">
          <Typography component="h2" variant="h5" itemProp="name">
            {eachItem.title}
          </Typography>
          <Typography component="p" variant="subtitle1" itemProp="description">
            {eachItem.subtitle}
          </Typography>
        </CardContent>
        <CardActions className="actions">
          <Button
            component="a"
            variant="contained"
            color="primary"
            href={eachItem.link}
            draggable={false}
            fullWidth
            itemProp="url"
          >
            Sewa
          </Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <section id="assets">
      <Typography component="h1" variant="h3">
        Our Assets Ready to Go
      </Typography>
      <hr />
      <div className="scrollable">{items.map(renderItem)}</div>
    </section>
  );
}
