import "./Projects.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";

import SubsectionTabs from "../components/SubsectionTabs";

import data from "../../contents/projects";

export default function Projects() {
  return (
    <section id="projects">
      <Typography component="h1" variant="h5" gutterBottom>
        Project Portfolio
      </Typography>
      <SubsectionTabs sectionID="projects" groupedItems={data} />
    </section>
  );
}
