import Schema from "./typedef";
import contact from "../contact";

export default {
  navItems: [
    {
      name: "Home",
      id: "welcome",
    },
    {
      name: "Services",
      id: "services",
    },
    {
      name: "Projects",
      id: "projects",
    },
    {
      name: "Assets",
      id: "assets",
    },
    {
      name: "Contact",
      id: "contact",
    },
    {
      name: "About",
      id: "about",
    },
  ],
  actionButtons: [
    {
      title: "Tokopedia",
      desc: "Browse our store via Tokopedia",
      icon: require("../../../assets/tokopedia.svg").default as string,
      link: "https://www.tokopedia.com/arms-material",
    },
    {
      title: "WhatsApp",
      desc: "Contact us via WhatsApp",
      icon: require("../../../assets/whatsapp.svg").default as string,
      link: contact.whatsapp,
      hasColor: true,
    },
  ],
} as Schema;
