import "./ServicesDetail.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";

import services from "../../contents/services";
import SubsectionScroll, {
  SubsectionScrollItem,
} from "../components/SubsectionScroll";

export default function ServicesDetail() {
  // Map to list item
  let items: SubsectionScrollItem[] = [];
  for (let eachService of services) {
    if (eachService.type === 0) {
      items.push({
        id: eachService.id,
        label: eachService.title,
        content: eachService.content,
      });
    }
  }

  return (
    <section id="services-detail">
      <Typography component="h1" variant="h5" gutterBottom>
        Our Services in Details
      </Typography>
      <SubsectionScroll sectionID="services-detail" items={items} />
    </section>
  );
}
