import Schema from "./typedef";

export default {
  "2022": [
    {
      id: "giyanti_project",
      label: "Quarry Giyanti Project",
      content: require("./2022/giyanti_project.md").default as string,
    },
    {
      id: "kci_2022",
      label: "Rental Mini Excavator Kereta Cepat",
      content: require("./2022/kci_2022.md").default as string,
    },
    {
      id: "dump_truck_grogol",
      label: "Rental Dump Truck MRT Grogol",
      content: require("./2022/dump_truck_grogol.md").default as string,
    },
    {
      id: "cut_n_fill_jatiluhur",
      label: "Cut And Fill Satlat Makobrimob Jatiluhur",
      content: require("./2022/cut_n_fill_jatiluhur.md").default as string,
    },
  ],
  "2021": [
    {
      id: "jatisari",
      label: "Jatisari Project",
      content: require("./2021/jatisari.md").default as string,
    },
  ],
  "2020": [
    {
      id: "cibitung",
      label: "Cut and Fill Cibitung",
      content: require("./2020/cibitung.md").default as string,
    },
  ],
  "2019": [
    {
      id: "saketi-sodong",
      label: "Cut and Fill Sodong Cikhen Farm",
      content: require("./2019/saketi-sodong.md").default as string,
    },
    {
      id: "jakarta-warung_buncit",
      label: "Pengurugan Warung Buncit",
      content: require("./2019/jakarta-warung_buncit.md").default as string,
    },
    {
      id: "cibinong-lipi",
      label: "Land Clearing & Pengurugan LIPI",
      content: require("./2019/cibinong-lipi.md").default as string,
    },
  ],
  "2018": [
    {
      id: "jakarta-turap",
      label: "Pengurugan dan Pemasangan Turap",
      content: require("./2018/jakarta-turap.md").default as string,
    },
    {
      id: "jakarta-duren_sawit",
      label: "Pembongkaran Gedung",
      content: require("./2018/jakarta-duren_sawit.md").default as string,
    },
  ],
  "2017": [
    {
      id: "karawang-sungai_buntu",
      label: "Pembentukan Tambak Udang",
      content: require("./2017/karawang-sungai_buntu.md").default as string,
    },
    {
      id: "sukabumi-sagaranten",
      label: "Pembentukan Badan Jalan",
      content: require("./2017/sukabumi-sagaranten.md").default as string,
    },
  ],
} as Schema;
