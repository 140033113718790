import "./App.sass";

import React from "react";

import WelcomeSection from "./sections/Welcome";
import ServicesSection from "./sections/Services";
import ServicesDetailSection from "./sections/ServicesDetail";
import ProjectsSection from "./sections/Projects";
import AssetsSection from "./sections/Assets";
import ContactSection from "./sections/Contact";
import AboutSection from "./sections/About";

import NavDrawer from "./components/NavDrawer";
import HeaderBar from "./components/HeaderBar";
import ActionButtons from "./components/ActionButtons";

export default function App(): JSX.Element {
  const [showMenu, setShowMenu] = React.useState(false);

  function switchMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <main className="mobile">
      <NavDrawer show={showMenu} setShow={setShowMenu} />
      <HeaderBar onMenuClick={switchMenu} />
      <WelcomeSection />
      <ServicesSection />
      <ServicesDetailSection />
      <ProjectsSection />
      <AssetsSection />
      <ContactSection />
      <AboutSection />
      <ActionButtons />
    </main>
  );
}
