import "./HeaderBar.sass";

import React from "react";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationIcon from "@material-ui/icons/Place";

import logo from "../../../assets/logo-simple.svg";
import facebookIcon from "../../../assets/facebook.svg";
import instagramIcon from "../../../assets/instagram.svg";

import data from "../../contents/contact";

export default function HeaderBar(): JSX.Element {
  const [elevation, setElevation] = React.useState(0);
  const [headerClass, setHeaderClass] = React.useState("");

  React.useEffect(() => {
    const smartScroll = document.querySelector(
      "div.smart_scroll"
    ) as HTMLElement;
    const headerElement = document.getElementsByTagName("header")[0];
    const triggerArea = smartScroll.clientHeight / 2;

    function scrollHandler() {
      if (smartScroll.scrollTop > headerElement.offsetTop - triggerArea) {
        setElevation(0);
        setHeaderClass("sticked");
      } else {
        setElevation(0);
        setHeaderClass("");
      }
    }

    smartScroll.addEventListener("scroll", scrollHandler);
    scrollHandler();

    return () => {
      smartScroll.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Paper
      component="header"
      elevation={elevation}
      className={headerClass}
      color="default"
    >
      <img src={logo} alt="ARMS General Contractor" draggable={false} />
      <Paper elevation={4} className="contact">
        <IconButton
          component="a"
          href={data.phone.link}
          draggable={false}
          title="Call Us"
        >
          <PhoneIcon />
        </IconButton>
        <IconButton
          component="a"
          href={data.email.link}
          draggable={false}
          title="Email Us"
        >
          <EmailIcon />
        </IconButton>
        <IconButton
          component="a"
          href={data.facebook}
          target="_blank"
          draggable={false}
          title="Visit Our Facebook Page"
        >
          <img src={facebookIcon} alt="Our Facebook Page" draggable={false} />
        </IconButton>
        <IconButton
          component="a"
          href={data.instagram}
          target="_blank"
          draggable={false}
          title="Visit Our Instagram"
        >
          <img src={instagramIcon} alt="Our Instagram" draggable={false} />
        </IconButton>
        <IconButton
          component="a"
          href={data.address.link}
          target="_blank"
          draggable={false}
          title="Our Location"
        >
          <LocationIcon />
        </IconButton>
      </Paper>
    </Paper>
  );
}
