import "./SubsectionTree.sass";

import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

export interface SubsectionTreeItem {
  id: string;
  label: string;
}

export interface SubsectionTreeFolder extends SubsectionTreeItem {
  items: SubsectionTreeItem[];
}

export interface SubsectionTreeProps {
  sectionID: string;
  content: SubsectionTreeFolder[];
  onChange: (folder_id: string, item_id: string) => void;
}

export default function SubsectionTree(
  props: SubsectionTreeProps
): JSX.Element {
  // Resolve first folder and item
  const firstFolder = props.content[0] ? props.content[0].id : "";
  const firstItem =
    props.content[0] && props.content[0].items[0]
      ? props.content[0] && props.content[0].items[0].id
      : "";

  // Reactive elements
  const [currentFolder, setCurrentFolder] = React.useState(firstFolder);
  const [currentItem, setCurrentItem] = React.useState(firstItem);

  // Attach listener on "/url/path#..." change
  React.useEffect(() => {
    function hashListener() {
      const currentHash = window.location.hash;
      const expectedPrefix = `#${props.sectionID}-`;
      if (currentHash.startsWith(expectedPrefix)) {
        const parsedHash = currentHash.replace(expectedPrefix, "").split("-");

        const folderID = parsedHash.shift() || "";
        const itemID = parsedHash.join("-");

        console.log(itemID);
        setCurrentFolder(folderID);
        setCurrentItem(itemID);
        props.onChange(folderID, itemID);
        window.location.hash = props.sectionID;
      }
    }

    window.addEventListener("hashchange", hashListener);

    // Tells ReactJS how to do cleanup
    return () => window.removeEventListener("hashchange", hashListener);
  }, []); // Square bracket means only run once

  // Function for rendering each folder
  function renderFolder(
    eachFolder: SubsectionTreeFolder,
    index: number
  ): JSX.Element {
    // Function for rendering each item, in each folder
    function renderItem(
      eachItem: SubsectionTreeItem,
      index: number
    ): JSX.Element {
      return (
        <Button
          key={index}
          variant="text"
          component="a"
          href={`#${props.sectionID}-${eachFolder.id}-${eachItem.id}`}
          color={currentItem === eachItem.id ? "primary" : "default"}
          draggable={false}
        >
          {eachItem.label}
        </Button>
      );
    }

    // This handler for folder header
    const onChangeHandler = () => {
      setCurrentFolder(eachFolder.id);
    };

    // HTML render for a folder
    return (
      <Accordion
        key={index}
        expanded={currentFolder === eachFolder.id}
        onChange={onChangeHandler}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              color={currentFolder === eachFolder.id ? "primary" : "inherit"}
            />
          }
        >
          <Typography
            component="h2"
            variant="h5"
            color={currentFolder === eachFolder.id ? "primary" : "textPrimary"}
          >
            {eachFolder.label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="details">
          {eachFolder.items.map(renderItem)}
        </AccordionDetails>
      </Accordion>
    );
  }

  // HTML Render for SubsectionTree component
  return (
    <aside className="subsection-tree">{props.content.map(renderFolder)}</aside>
  );
}
