import "./About.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import SwipeableViews, { SwipeableViewsProps } from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import VisionIcon from "@material-ui/icons/Visibility";
import MissionIcon from "@material-ui/icons/TrackChanges";

import data from "../../contents/about";

export default function About() {
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, selectedIndex: number) => {
    setTabIndex(selectedIndex);
  };

  const handleChangeIndex = (selectedIndex: number) => {
    setTabIndex(selectedIndex);
  };

  function renderVisionMission(): JSX.Element {
    return (
      <article className="vision_mission">
        <VisionIcon color="primary" />
        <Typography component="h1" variant="h4" color="primary">
          Vision
        </Typography>
        <Typography
          component="p"
          variant="body1"
          dangerouslySetInnerHTML={{ __html: data.vision }}
        />
        <br />
        <br />
        <MissionIcon color="primary" />
        <Typography component="h1" variant="h4" color="primary">
          Mission
        </Typography>
        <Typography
          component="p"
          variant="body1"
          dangerouslySetInnerHTML={{ __html: data.mission }}
        />
      </article>
    );
  }

  function renderHistory(): JSX.Element {
    return (
      <article className="history">
        <img src="/image/history-banner.jpg" alt="Photo of our works" />
        <Typography component="h1" variant="h4" color="primary">
          Our History
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: data.history }}
        />
      </article>
    );
  }

  const swipableContainerStyle = {
    height: "100%",
    WebkitOverflowScrolling: "touch",
  };

  return (
    <section id="about">
      <Typography component="h1" variant="h5" gutterBottom>
        About Our Company
      </Typography>
      <Divider />
      <Tabs
        className="selectors"
        value={tabIndex}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
      >
        <Tab label="Vision &amp; Mission" />
        <Tab label="History" />
      </Tabs>
      <SwipeableViews
        className="contents"
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
        containerStyle={swipableContainerStyle as SwipeableViewsProps}
      >
        {renderVisionMission()}
        {renderHistory()}
      </SwipeableViews>
    </section>
  );
}
