import "./ServicesDetail.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import services from "../../contents/services";
import SubsectionList, {
  SubsectionListItem,
} from "../components/SubsectionList";

export default function ServicesDetail() {
  // Resolve first variable
  let firstID: string = "";
  let firstPage: string = "";
  if (services[0].type === 0) {
    firstID = services[0].id;
    firstPage = services[0].content;
  }

  // Reactive variables
  const [selectedID, setSelectedID] = React.useState(firstID);
  const [page, setPage] = React.useState(firstPage);

  // Listener when user select a subsection
  function onSelectSubsection(id: string) {
    for (const eachService of services) {
      if (eachService.type === 0 && eachService.id === id) {
        setSelectedID(eachService.id);
        setPage(eachService.content);
        break;
      }
    }
  }

  // Map to list item
  let items: SubsectionListItem[] = [];
  for (let eachService of services) {
    if (eachService.type === 0) {
      items.push({
        id: eachService.id,
        label: eachService.title,
      });
    }
  }

  return (
    <section id="services-detail">
      <Typography component="h1" variant="h3">
        Further Information About Our Services
      </Typography>
      <div className="content">
        <SubsectionList
          sectionID="services-detail"
          items={items}
          onChange={onSelectSubsection}
        />
        <Paper
          key={selectedID}
          component="article"
          dangerouslySetInnerHTML={{ __html: page }}
        />
      </div>
    </section>
  );
}
