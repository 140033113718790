import "./NavDrawer.sass";

import React from "react";
import Button from "@material-ui/core/Button";
import Circle from "@material-ui/icons/Lens";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationIcon from "@material-ui/icons/Place";

import facebookIcon from "../../../assets/facebook.svg";
import instagramIcon from "../../../assets/instagram.svg";

import content from "../../contents/main";
import { NavItem } from "../../contents/main/typedef";
import contactData from "../../contents/contact";

export interface NavDrawerProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

function renderItem(
  data: NavItem,
  index: number,
  selectedIndex: number,
  onClick: () => void
): JSX.Element {
  if (index === selectedIndex)
    return (
      <Button
        key={index}
        component="a"
        color="primary"
        startIcon={<Circle />}
        href={"#" + data.id}
        draggable={false}
        onClick={onClick}
      >
        {data.name}
      </Button>
    );
  else
    return (
      <Button
        key={index}
        component="a"
        startIcon={<Circle />}
        href={"#" + data.id}
        draggable={false}
        onClick={onClick}
      >
        {data.name}
      </Button>
    );
}

function resolveIndex(scrollTop: number, items: NavItem[]): number {
  for (let i = 0; i < items.length - 1; i++) {
    const eachItem = items[i + 1];
    const eachElement = document.getElementById(eachItem.id);
    if (eachElement == null) continue;
    if (scrollTop < eachElement.offsetTop - 10) {
      return i;
    }
  }
  return items.length - 1;
}

export default function NavDrawer(props: NavDrawerProps) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    const smartScroll = document.querySelector(
      "div.smart_scroll"
    ) as HTMLElement;

    function scrollHandler() {
      const newIndex = resolveIndex(smartScroll.scrollTop, content.navItems);
      setSelectedIndex(newIndex);
    }

    smartScroll.addEventListener("scroll", scrollHandler);
    scrollHandler();

    return () => {
      smartScroll.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  function handleClick() {
    props.setShow(false);
    window.location.hash = window.location.hash;
    // const safariChecker = /^((?!chrome|android).)*safari/i;
    // const isSafari = safariChecker.test(navigator.userAgent);
    // if (isSafari) {
    //   window.location.hash = window.location.hash;
    // } else {
    //   window.location.hash = window.location.hash;
    // }
  }

  return (
    <SwipeableDrawer
      className="navdrawer"
      anchor="left"
      open={props.show}
      onOpen={() => props.setShow(true)}
      onClose={() => props.setShow(false)}
    >
      <nav>
        {content.navItems.map((value, index) =>
          renderItem(value, index, selectedIndex, handleClick)
        )}
      </nav>
      <Paper elevation={4} className="contact">
        <IconButton
          component="a"
          href={contactData.phone.link}
          draggable={false}
          title="Call Us"
        >
          <PhoneIcon />
        </IconButton>
        <IconButton
          component="a"
          href={contactData.email.link}
          draggable={false}
          title="Email Us"
        >
          <EmailIcon />
        </IconButton>
        <IconButton
          component="a"
          href={contactData.facebook}
          target="_blank"
          draggable={false}
          title="Visit Our Facebook Page"
        >
          <img src={facebookIcon} alt="Our Facebook Page" draggable={false} />
        </IconButton>
        <IconButton
          component="a"
          href={contactData.instagram}
          target="_blank"
          draggable={false}
          title="Visit Our Instagram"
        >
          <img src={instagramIcon} alt="Our Instagram" draggable={false} />
        </IconButton>
        <IconButton
          component="a"
          href={contactData.address.link}
          target="_blank"
          draggable={false}
          title="Our Office Location"
        >
          <LocationIcon />
        </IconButton>
      </Paper>
    </SwipeableDrawer>
  );
}
