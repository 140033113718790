import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../theme";
import App from "../homepage/App";

export default function Homepage(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Arms General Contractor</title>
        <meta
          name="description"
          content="We provide finest quality of supplier, transporter, and heavy equipment rental services."
        />
        <meta
          name="keywords"
          content="kontraktor, bekasi, terdekat, nearest, contractor, adhi, sejasa, sewa, truk, truck, backhoe, jasa, rental"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <App />
    </ThemeProvider>
  );
}
