import "./App.sass";

import React from "react";
import DesktopApp from "./desktop/App";
import MobileApp from "./mobile/App";

export default function App(): JSX.Element {
  // Reactive variables
  const [pageWidth, setPageWidth] = React.useState(NaN);

  // Runs when the page is ready
  React.useEffect(() => {
    // This will be called each time window resized
    function updateHandler() {
      setPageWidth(window.innerWidth);
    }

    window.addEventListener("resize", updateHandler);
    updateHandler();

    // Tells React how to do cleanup
    return () => {
      window.removeEventListener("resize", updateHandler);
    };
  }, []); // Empty array means only run when ready, not each render

  function renderApp(): JSX.Element {
    if (pageWidth < 1000) {
      return <MobileApp />;
    } else {
      return <DesktopApp />;
    }
  }

  return <div className="smart_scroll">{renderApp()}</div>;
}
