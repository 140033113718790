import "./About.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import VisionIcon from "@material-ui/icons/Visibility";
import MissionIcon from "@material-ui/icons/TrackChanges";

import data from "../../contents/about";
import SubsectionList from "../components/SubsectionList";

export default function About() {
  const [currentID, setCurrentID] = React.useState("vision_mission");

  // Listener when user select a subsection
  function onSelectSubsection(id: string) {
    setCurrentID(id);
  }

  function renderVisionMission(): JSX.Element {
    return (
      <Paper component="article" className="vision_mission">
        <VisionIcon color="primary" />
        <Typography component="h1" variant="h4" color="primary">
          Vision
        </Typography>
        <Typography
          component="p"
          variant="h5"
          dangerouslySetInnerHTML={{ __html: data.vision }}
        />
        <br />
        <br />
        <MissionIcon color="primary" />
        <Typography component="h1" variant="h4" color="primary">
          Mission
        </Typography>
        <Typography
          component="p"
          variant="h5"
          dangerouslySetInnerHTML={{ __html: data.mission }}
        />
      </Paper>
    );
  }

  function renderHistory(): JSX.Element {
    return (
      <Paper component="article" className="history">
        <img src="/image/history-banner.jpg" alt="Photo of our works" />
        <Typography component="h1" variant="h4" color="primary">
          Our History
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          dangerouslySetInnerHTML={{ __html: data.history }}
        />
      </Paper>
    );
  }

  function renderContent(): JSX.Element {
    if (currentID === "vision_mission") {
      return renderVisionMission();
    } else if (currentID === "history") {
      return renderHistory();
    } else {
      return <article>Wrong ID</article>;
    }
  }

  return (
    <section id="about">
      <Typography component="h1" variant="h3">
        About Our Company
      </Typography>
      <div className="content">
        <SubsectionList
          sectionID="about"
          items={data.listItems}
          onChange={onSelectSubsection}
        />
        {renderContent()}
      </div>
    </section>
  );
}
