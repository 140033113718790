import "./Welcome.sass";

import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

import logo from "../../../assets/logo.svg";

import content from "../../contents/welcome";

export default function Welcome(): JSX.Element {
  return (
    <section id="welcome">
      <div className="logo" color="default">
        <img src={logo} alt="ARMS General Contractor" draggable={false} />
      </div>
      <Carousel
        images={content.slideItems}
        className="slideshow"
        autoPlayInterval={3000}
        isLoop={true}
        isAutoPlaying={true}
        hasCaptions={false}
        hasDotButtons={false}
        hasIndexBoard={false}
        hasLeftButton={false}
        hasRightButton={false}
        hasThumbnails={false}
        hasMediaButton={false}
        hasSizeButton={false}
      />
    </section>
  );
}
