import "./App.sass";

import React from "react";

import WelcomeSection from "./sections/Welcome";
import ServicesSection from "./sections/Services";
import ServicesDetailSection from "./sections/ServicesDetail";
import ProjectsSection from "./sections/Projects";
import AssetsSection from "./sections/Assets";
import AboutSection from "./sections/About";
import ContactSection from "./sections/Contact";

import HeaderBar from "./components/HeaderBar";
import SideNav from "./components/SideNav";
import ActionButtons from "./components/ActionButtons";

export default function App(): JSX.Element {
  return (
    <main className="desktop">
      <WelcomeSection />
      <HeaderBar />
      <ServicesSection />
      <ServicesDetailSection />
      <ProjectsSection />
      <AssetsSection />
      <ContactSection />
      <AboutSection />
      <SideNav />
      <ActionButtons />
    </main>
  );
}
