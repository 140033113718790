import "./SideNav.sass";

import React from "react";
import Button from "@material-ui/core/Button";
import Circle from "@material-ui/icons/Lens";

import content from "../../contents/main";
import { NavItem } from "../../contents/main/typedef";

function renderItem(
  data: NavItem,
  index: number,
  selectedIndex: number
): JSX.Element {
  if (index === selectedIndex)
    return (
      <Button
        key={index}
        component="a"
        color="primary"
        startIcon={<Circle />}
        href={"#" + data.id}
        draggable={false}
      >
        {data.name}
      </Button>
    );
  else
    return (
      <Button
        key={index}
        component="a"
        startIcon={<Circle />}
        href={"#" + data.id}
        draggable={false}
      >
        {data.name}
      </Button>
    );
}

function resolveIndex(scrollTop: number, items: NavItem[]): number {
  for (let i = 0; i < items.length - 1; i++) {
    const eachItem = items[i + 1];
    const eachElement = document.getElementById(eachItem.id);
    if (eachElement == null) continue;
    if (scrollTop < eachElement.offsetTop - 10) {
      return i;
    }
  }
  return items.length - 1;
}

export default function SideNav(): JSX.Element {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    const smartScroll = document.querySelector(
      "div.smart_scroll"
    ) as HTMLElement;

    function scrollHandler() {
      const newIndex = resolveIndex(smartScroll.scrollTop, content.navItems);
      setSelectedIndex(newIndex);
    }

    smartScroll.addEventListener("scroll", scrollHandler);
    scrollHandler();

    return () => {
      smartScroll.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <nav>
      {content.navItems.map((value, index) =>
        renderItem(value, index, selectedIndex)
      )}
    </nav>
  );
}
