import Schema from "./typedef"

export default {
  slideItems: [
    {
      src: '/image/cut_n_fill.jpg',
      alt: 'Working backhoe from ARMS'
    },
    {
      src: '/image/heavy_equipment_rental.jpg',
      alt: 'ARMS Contractor workers digging soils'
    },
    {
      src: '/image/supplier.jpg',
      alt: 'Workers digging stones with heavy machines'
    },
    {
      src: '/image/transporter.jpg',
      alt: 'Backhoe on top of transporter'
    }
  ]
} as Schema;