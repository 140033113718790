import "./SubsectionScroll.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

export interface SubsectionScrollItem {
  id: string;
  label: string;
  content: string;
}

export interface SubsectionScrollProps {
  sectionID: string;
  items: SubsectionScrollItem[];
}

export default function SubsectionScroll(props: SubsectionScrollProps) {
  function renderItem(
    eachItem: SubsectionScrollItem,
    index: number
  ): JSX.Element {
    return (
      <article key={index} id={props.sectionID + "-" + eachItem.id}>
        <div className="titlebar">
          <Typography component="h1" variant="h6" color="primary">
            {eachItem.label}
          </Typography>
          <Divider />
        </div>
        <p dangerouslySetInnerHTML={{ __html: eachItem.content }} />
      </article>
    );
  }

  return <div className="subsection-scroll">{props.items.map(renderItem)}</div>;
}
