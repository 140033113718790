import "./Services.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ShopIcon from "@material-ui/icons/Shop";

import items, { ServicesSchema } from "../../contents/services";

export default function Services() {
  function renderTopCell(eachItem: ServicesSchema, index: number): JSX.Element {
    return (
      <TableCell key={index}>
        <Typography component="h2" variant="subtitle1">
          {eachItem.title}
        </Typography>
      </TableCell>
    );
  }

  function renderMiddleCell(
    eachItem: ServicesSchema,
    index: number
  ): JSX.Element {
    return (
      <TableCell key={index}>
        <img src={eachItem.thumbnail} alt={eachItem.title} />
        {eachItem.description}
      </TableCell>
    );
  }

  function renderPrice(input: number): string {
    const inputString: string = input.toString();
    let result: string = "";
    let charWritten: number = 0;
    for (let i = inputString.length - 1; i >= 0; i--) {
      if (charWritten > 0 && !(charWritten % 3)) {
        result = "." + result;
      }
      result = inputString[i] + result;
      charWritten++;
    }
    return "Rp." + result;
  }

  function renderUnit(input: "m2" | "hour" | "trip" | "m3"): JSX.Element {
    switch (input) {
      case "m2":
        return (
          <sub>
            /m<sup>2</sup>
          </sub>
        );
      case "hour":
        return <sub>/hour</sub>;
      case "trip":
        return <sub>/trip</sub>;
      case "m3":
        return (
          <sub>
            /m<sup>3</sup>
          </sub>
        );
    }
  }

  function renderBottomCell(
    eachItem: ServicesSchema,
    index: number
  ): JSX.Element {
    switch (eachItem.type) {
      case 0:
        return (
          <TableCell key={index}>
            <Typography component="label" variant="caption">
              Starts from
            </Typography>
            <br />
            <Typography component="strong" variant="subtitle1">
              {renderPrice(eachItem.price)}
              {renderUnit(eachItem.unit)}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              href={"#services-detail-" + eachItem.id}
              startIcon={<SearchIcon />}
              draggable={false}
            >
              Lebih Lengkap
            </Button>
          </TableCell>
        );
      case 1:
        return (
          <TableCell key={index}>
            <Button
              variant="contained"
              color="primary"
              href={eachItem.link}
              startIcon={<ShopIcon />}
              draggable={false}
            >
              Mulai Belanja
            </Button>
          </TableCell>
        );
    }
  }

  return (
    <section id="services">
      <Typography component="h1" variant="h3">
        Our Services
      </Typography>
      <Paper component="div">
        <Table aria-label="List of our services">
          <TableHead>
            <TableRow>{items.map(renderTopCell)}</TableRow>
          </TableHead>
          <TableBody>
            <TableRow>{items.map(renderMiddleCell)}</TableRow>
            <TableRow>{items.map(renderBottomCell)}</TableRow>
          </TableBody>
        </Table>
      </Paper>
    </section>
  );
}
