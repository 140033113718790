export default {
  address: {
    label: "Kp. Tunggilis, RT.03 RW.06 Ds. Gandoang, Cileungsi, Bogor",
    link: "https://maps.app.goo.gl/vVM4y1R8GouaPBqK9",
  },
  phone: {
    label: "+62 813-8071-6563",
    link: "tel:+6281380716563",
  },
  email: {
    label: "contact@armscontractor.co.id",
    link: "mailto:contact@armscontractor.co.id",
  },
  homephone: "(021) 22-825-048",
  embedMaps:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.815311248361!2d107.02573647496807!3d-6.417771793573141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69971c6a940911%3A0x1bf56a91c6b0458f!2sWorkshop%20PT%20ARMS%20CONTRACTOR%20Rental%20Alat%20Berat%20%26%20Trucking!5e0!3m2!1sid!2sid!4v1701361217387!5m2!1sid!2sid",
  whatsapp: "https://api.whatsapp.com/send?phone=6281380716563",
  facebook: "about:blank",
  instagram: "https://www.instagram.com/armscontractor",
};
