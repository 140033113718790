import "./Assets.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import data from "../../contents/assets";
import AssetsSchema from "../../contents/assets/typedef";

export default function Assets() {
  function renderItem(eachItem: AssetsSchema, index: number): JSX.Element {
    return (
      <Card key={index} component="article">
        <img src={eachItem.image} alt={eachItem.title} draggable={false} />
        <CardContent className="content">
          <Typography component="h1" variant="h5">
            {eachItem.title}
          </Typography>
          <Typography component="p" variant="subtitle1">
            {eachItem.subtitle}
          </Typography>
        </CardContent>
        <CardActions className="actions">
          <Button
            component="a"
            variant="contained"
            color="primary"
            href={eachItem.link}
            draggable={false}
            fullWidth
          >
            Sewa
          </Button>
        </CardActions>
      </Card>
    );
  }

  return (
    <section id="assets">
      <Typography component="h1" variant="h5" gutterBottom>
        Our Assets Ready to Go
      </Typography>
      <Divider />
      <div className="scrollable">{data.map(renderItem)}</div>
    </section>
  );
}
