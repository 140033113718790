import "./Services.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import ShopIcon from "@material-ui/icons/Shop";

import items, { ServicesSchema } from "../../contents/services";

export default function Services() {
  function renderPrice(input: number): string {
    const inputString: string = input.toString();
    let result: string = "";
    let charWritten: number = 0;
    for (let i = inputString.length - 1; i >= 0; i--) {
      if (charWritten > 0 && !(charWritten % 3)) {
        result = "." + result;
      }
      result = inputString[i] + result;
      charWritten++;
    }
    return "Rp." + result;
  }

  function renderUnit(input: "m2" | "hour" | "trip" | "m3"): JSX.Element {
    switch (input) {
      case "m2":
        return (
          <sub>
            /m<sup>2</sup>
          </sub>
        );
      case "hour":
        return <sub>/hour</sub>;
      case "trip":
        return <sub>/trip</sub>;
      case "m3":
        return (
          <sub>
            /m<sup>3</sup>
          </sub>
        );
    }
  }

  function renderItem(eachItem: ServicesSchema, index: number): JSX.Element {
    switch (eachItem.type) {
      case 0:
        return (
          <Paper key={index} component="article">
            <Typography component="h2" variant="h5">
              {eachItem.title}
            </Typography>
            <img src={eachItem.thumbnail} alt={eachItem.title} />
            <Typography>{eachItem.description}</Typography>
            <Typography component="label">Starts from</Typography>
            <Typography component="strong" variant="h5">
              {renderPrice(eachItem.price)}
              {renderUnit(eachItem.unit)}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              href={"#services-detail-" + eachItem.id}
              startIcon={<SearchIcon />}
              draggable={false}
            >
              Lebih Lengkap
            </Button>
          </Paper>
        );
      case 1:
        return (
          <Paper key={index} component="article">
            <Typography component="h2" variant="h5">
              {eachItem.title}
            </Typography>
            <img src={eachItem.thumbnail} alt={eachItem.title} />
            <Typography>{eachItem.description}</Typography>
            <Button
              variant="contained"
              color="primary"
              href={eachItem.link}
              startIcon={<ShopIcon />}
              draggable={false}
            >
              Mulai Belanja
            </Button>
          </Paper>
        );
    }
  }

  return (
    <section id="services">
      <Typography component="h1" variant="h5" gutterBottom>
        Our Services
      </Typography>
      <Divider />
      <div className="scrollable">{items.map(renderItem)}</div>
    </section>
  );
}
