import "./ActionButtons.sass";

import React from "react";
import Fab from "@material-ui/core/Fab";

import content from "../../contents/main";
import { ActionButtonItem } from "../../contents/main/typedef";

export default function ActionButtons(): JSX.Element {
  function renderItem(data: ActionButtonItem, index: number): JSX.Element {
    return (
      <Fab
        key={index}
        href={data.link}
        aria-label={data.title}
        color={data.hasColor ? "secondary" : "default"}
        draggable={false}
        target="_blank"
      >
        <img src={data.icon} alt={data.desc} draggable={false} />
      </Fab>
    );
  }

  return (
    <aside className="action_buttons">
      {content.actionButtons.map(renderItem)}
    </aside>
  );
}
