import "./SubsectionList.sass";

import React from "react";
import Button from "@material-ui/core/Button";
import NextIcon from "@material-ui/icons/ArrowForwardIos";

export interface SubsectionListItem {
  id: string;
  label: string;
}

export interface SubsectionListProps {
  sectionID: string;
  items: SubsectionListItem[];
  onChange: (id: string) => void;
}

export default function SubsectionList(
  props: SubsectionListProps
): JSX.Element {
  // Resolve first selected item
  const firstSelectedID = props.items[0] ? props.items[0].id : "";

  // Reactive variable
  const [selectedID, setSelectedID] = React.useState(firstSelectedID);

  // Attach listener on "/url/path#..." change
  React.useEffect(() => {
    function hashListener() {
      const currentHash = window.location.hash;
      const expectedPrefix = `#${props.sectionID}-`;
      if (currentHash.startsWith(expectedPrefix)) {
        const id = currentHash.replace(expectedPrefix, "");
        setSelectedID(id);
        props.onChange(id);
        window.location.hash = props.sectionID;
      }
    }

    window.addEventListener("hashchange", hashListener);

    // Tells ReactJS how to do cleanup
    return () => window.removeEventListener("hashchange", hashListener);
  }, []); // Square bracket means only run once

  // Render each item
  function renderItem(
    eachItem: SubsectionListItem,
    index: number
  ): JSX.Element {
    return (
      <Button
        key={index}
        variant="text"
        component="a"
        href={`#${props.sectionID}-${eachItem.id}`}
        color={selectedID === eachItem.id ? "primary" : "default"}
        draggable={false}
        endIcon={<NextIcon />}
      >
        {eachItem.label}
      </Button>
    );
  }

  // HTML Render for SubsectionList component
  return (
    <aside className="subsection-list">{props.items.map(renderItem)}</aside>
  );
}
