import "./Contact.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import AddressIcon from "@material-ui/icons/LocationOn";
import HomephoneIcon from "@material-ui/icons/Phone";
import PhoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";

import data from "../../contents/contact";

interface ListItem {
  icon: JSX.Element;
  label: string;
  link?: string;
  isNewTab?: boolean;
}

export default function Contact() {
  // Which stuffs need to be added on list?
  const items: ListItem[] = [
    {
      icon: <AddressIcon titleAccess="Location" />,
      isNewTab: true,
      ...data.address,
    },
    {
      icon: <HomephoneIcon titleAccess="Telephone Number" />,
      label: data.homephone,
    },
    {
      icon: <PhoneIcon titleAccess="Phone Number" />,
      ...data.phone,
    },
    {
      icon: <EmailIcon titleAccess="Email" />,
      ...data.email,
    },
  ];

  function renderItem(eachItem: ListItem, index: number): JSX.Element {
    return (
      <Typography key={index} component="li" variant="subtitle1">
        {eachItem.icon}
        {eachItem.label}
      </Typography>
    );
  }

  return (
    <section id="contact">
      <Typography component="h1" variant="h5" gutterBottom>
        Contact Us
      </Typography>
      <Divider />
      <div className="scrollable">
        <Paper className="maps" elevation={4}>
          <iframe scrolling="no" src={data.embedMaps} />
        </Paper>
        <ul>{items.map(renderItem)}</ul>
      </div>
    </section>
  );
}
