import "./SubsectionTabs.sass";

import React from "react";
import SwipeableViews, { SwipeableViewsProps } from "react-swipeable-views";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import SubsectionScroll from "./SubsectionScroll";

export interface SubsectionTabsItem {
  id: string;
  label: string;
  content: string;
}

export interface SubsectionTabsProps {
  sectionID: string;
  groupedItems: { [key: string]: SubsectionTabsItem[] };
}

export default function SubsectionTabs(props: SubsectionTabsProps) {
  const [currentGroupIndex, setGroupIndex] = React.useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, selectedIndex: number) => {
    setGroupIndex(selectedIndex);
  };

  const handleChangeIndex = (selectedIndex: number) => {
    setGroupIndex(selectedIndex);
  };

  const groupNames: string[] = Object.keys(props.groupedItems).reverse();

  function renderSelector(eachGroupName: string, index: number): JSX.Element {
    return <Tab label={eachGroupName} key={index} />;
  }

  function renderContent(eachGroupName: string, index: number): JSX.Element {
    const items = props.groupedItems[eachGroupName];
    return (
      <SubsectionScroll
        key={index}
        sectionID={props.sectionID + "-" + eachGroupName}
        items={items}
      />
    );
  }

  const swipableContainerStyle = {
    height: "100%",
    WebkitOverflowScrolling: "touch",
  };

  return (
    <div className="subsection-tabs">
      <Tabs
        className="selectors"
        value={currentGroupIndex}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
      >
        {groupNames.map(renderSelector)}
      </Tabs>
      <SwipeableViews
        className="contents"
        index={currentGroupIndex}
        onChangeIndex={handleChangeIndex}
        containerStyle={swipableContainerStyle as SwipeableViewsProps}
      >
        {groupNames.map(renderContent)}
      </SwipeableViews>
    </div>
  );
}
