import "./HeaderBar.sass";

import React from "react";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "../../../assets/logo-simple.svg";

export interface HeaderBarProps {
  onMenuClick: () => void;
}

export default function HeaderBar(props: HeaderBarProps) {
  const [headerClass, setHeaderClass] = React.useState("on-top");

  React.useEffect(() => {
    const smartScroll = document.querySelector(
      "div.smart_scroll"
    ) as HTMLElement;

    function scrollHandler() {
      if (smartScroll.scrollTop > smartScroll.clientHeight / 2) {
        setHeaderClass("");
      } else {
        setHeaderClass("on-top");
      }
    }

    smartScroll.addEventListener("scroll", scrollHandler);
    scrollHandler();

    return () => {
      smartScroll.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Paper component="header" className={headerClass} elevation={4}>
      <IconButton title="Menu drawer" onClick={props.onMenuClick}>
        <MenuIcon />
      </IconButton>
      <img src={logo} alt="Arms General Contractor" />
    </Paper>
  );
}
