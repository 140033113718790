import "./ActionButtons.sass";

import React from "react";
import Button from "@material-ui/core/Button";

import content from "../../contents/main";
import { ActionButtonItem } from "../../contents/main/typedef";

export default function ActionButtons(): JSX.Element {
  function renderItem(data: ActionButtonItem, index: number): JSX.Element {
    return (
      <Button
        key={index}
        href={data.link}
        startIcon={<img src={data.icon} alt={data.desc} draggable={false} />}
        variant="contained"
        color={data.hasColor ? "secondary" : "default"}
        draggable={false}
        target="_blank"
        title={data.desc}
      >
        {data.title}
      </Button>
    );
  }

  return (
    <aside className="action_buttons">
      {content.actionButtons.map(renderItem)}
    </aside>
  );
}
