import Schema from "./typedef";
import contact from "../contact";

export default [
  {
    image: "/image/caterpillar-313d2_lgp.jpg",
    title: "Caterpillar 313D2 + Bucket",
    subtitle: "2 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/caterpillar-311c.jpg",
    title: "Caterpillar 311C + Bucket",
    subtitle: "2 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/komatsu-pc75uu.jpg",
    title: "Komatsu PC75UU-2",
    subtitle: "1 unit",
    link: contact.whatsapp,
  },
  {
    image: "/image/komatsu-d65.jpg",
    title: "Komatsu D65-6",
    subtitle: "2 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/fuso-fighter.jpg",
    title: "Fuso Fighter Dump Truck",
    subtitle: "3 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/kobelco-sk200.jpg",
    title: "Kobelco SK200",
    subtitle: "5 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/komatsu-pc200.jpg",
    title: "Komatsu PC200",
    subtitle: "4 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/hino-fm260ti.png",
    title: "Hino FM260Ti Dump Truck",
    subtitle: "10 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/hino-dutro-300.png",
    title: "Hino Dutro 300",
    subtitle: "10 units",
    link: contact.whatsapp,
  },
  {
    image: "/image/komatsu-pc78uu.jpg",
    title: "Komatsu PC78UU-6",
    subtitle: "2 units",
    link: contact.whatsapp,
  },
] as Schema[];
