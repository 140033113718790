interface ListItem {
  id: string;
  label: string;
}

function removeRootTag(input: string): string {
  return input.substr(3).substr(0, input.length - 8);
}

export default {
  vision: removeRootTag(require("./vision.md").default),
  mission: removeRootTag(require("./mission.md").default),
  history: removeRootTag(require("./history.md").default),
  listItems: [
    {
      id: "vision_mission",
      label: "Vision & Mission",
    },
    {
      id: "history",
      label: "Our History",
    },
  ] as ListItem[],
};
