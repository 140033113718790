import { Schema } from "./typedef";

export default [
  {
    type: 0,
    id: "cut_n_fill",
    title: "Cut & Fill",
    thumbnail: "/image/cut_n_fill.jpg",
    description: "Sweep the soils to keep the ground straight",
    price: 30000,
    unit: "m3",
    content: require("./cut_n_fill.md").default as string,
  },
  {
    type: 0,
    id: "land_clearing",
    title: "Land Clearing",
    thumbnail: "/image/land_clearing.jpg",
    description:
      "Keeps the ground clear from obstacles including trees and rocks",
    price: 15000,
    unit: "m2",
    content: require("./land_clearing.md").default as string,
  },
  {
    type: 0,
    id: "heavy_equipment_rental",
    title: "Heavy Equipment Rental",
    thumbnail: "/image/heavy_equipment_rental.jpg",
    description: "Well maintain heavy equipment with best price.",
    price: 100000,
    unit: "hour",
    content: require("./heavy_equipment_rental.md").default as string,
  },
  {
    type: 0,
    id: "transporter",
    title: "Transporter",
    thumbnail: "/image/transporter.jpg",
    description: "Heavy stuffs delivery services.",
    price: 200000,
    unit: "trip",
    content: require("./transporter.md").default as string,
  },
  {
    type: 1,
    title: "Supplier",
    thumbnail: "/image/supplier.jpg",
    description: "Supply best materials to your projects",
    link: "https://www.tokopedia.com/arms-material",
  },
  {
    type: 0,
    id: "civil_contractor",
    title: "Civil Contractor",
    thumbnail: "/image/civil_contractor.jpeg",
    description: "Architecture and Interior Design",
    price: 5000000,
    unit: "m2",
    content: require("./civil_contractor.md").default as string,
  },
] as Schema[];

// Untuk digunakan oleh views
export type ServicesSchema = Schema;
