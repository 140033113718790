import "./Projects.sass";

import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import projects from "../../contents/projects";
import SubsectionTree, {
  SubsectionTreeFolder,
} from "../components/SubsectionTree";

// Map to tree content
let folders: SubsectionTreeFolder[] = [];
const folderNames = Object.keys(projects).reverse();
for (const eachYear of folderNames) {
  folders.push({
    id: eachYear,
    label: eachYear,
    items: projects[eachYear],
  });
}

export default function Projects() {
  // Resolve first folder
  let firstFolder: string = "";
  if (folderNames[0]) {
    firstFolder = folderNames[0];
  }

  // Resolve first page
  let firstPage: string = "";
  if (firstFolder.length > 0 && projects[firstFolder][0]) {
    const pages = projects[firstFolder];
    firstPage = pages[0].content;
  }

  // Reactive variable
  const [page, setPage] = React.useState(firstPage);

  // Listener when user select a subsection
  function onSelectSubsection(folderID: string, itemID: string) {
    const selectedFolder = projects[folderID];
    for (const eachItem of selectedFolder) {
      if (eachItem.id === itemID) {
        setPage(eachItem.content);
        break;
      }
    }
  }

  return (
    <section id="projects">
      <Typography component="h1" variant="h3">
        Project Portfolio
      </Typography>
      <div className="content">
        <SubsectionTree
          sectionID="projects"
          content={folders}
          onChange={onSelectSubsection}
        />
        <Paper component="article" dangerouslySetInnerHTML={{ __html: page }} />
      </div>
    </section>
  );
}
